/* max-width: 990 */
@media only screen and (max-width: 1265px) {
    .OfferOwnerColumnLeft, .OfferOwnerColumnImageRight {
        float: none !important;
        width: 100% !important;
    }
    .OfferOwnerColumnLeft {
        text-align: left !important;
    }
    .OfferOwnerColumnImageRight {
        text-align: center !important;
    }
    .GridRow {
        margin-bottom: 20px;
    }
    .WrapperOfferOwner .OfferOwnerColumnLeft .GridIcon .Icon {
        display: block !important;
        float: left !important;
        margin-right: 10px !important;
        margin-left: 10px !important;
    }
}

.WrapperOfferOwner {
    line-height: 29px;
    text-transform: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
}

.WrapperOfferOwner .TextModule h2 {
    color: #212529;
    font-size: 30px;
    line-height: 50px;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
}

.WrapperOfferOwner .TextModule p {
    padding-top: 15px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0px;
    display: block;
    text-align: justify;

}

.WrapperOfferOwner .OfferOwnerColumnLeft {
    position: relative;
    float: left;
    width: 50%;
    text-align: right;
    padding-top: 80px;


}

.WrapperOfferOwner .OfferOwnerColumnLeft h3 {
    font-size: 17px;
    text-align: left;
    padding-left: 70px;
    text-transform: uppercase;
}

.WrapperOfferOwner .OfferOwnerColumnLeft p  {
    color: #999999;;
    line-height: 1.6;
    padding-top: 0px !important;
    padding-left: 70px
}

.WrapperOfferOwner .OfferOwnerColumnLeft .GridIcon {
    float: left;
    display: inline-block;
    position: relative;
    z-index: 2;
}

.WrapperOfferOwner .OfferOwnerColumnLeft .GridIcon .Icon {
    position: relative;
    color: #7f8c8d !important;
    border: 1px solid #7f8c8d !important;
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    width: 50px;
    height: 50px;
    padding: 10px;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    background: transparent;
}

.WrapperOfferOwner .OfferOwnerColumnImageRight {
    float: left;
    width: 50%;
    text-align: right;
    padding-top: 80px;
}

.WrapperOfferOwner .OfferOwnerColumnImageRight .Image {
    margin-left: 30px;
}

.WrapperOfferOwner .OfferOwnerColumnImageRight .Image img {
    max-width: 100%;
    margin: 0 auto;
    padding-bottom: 85px;
    /*vertical-align: bottom;*/
}

.OfferOwnerMainContent {
    position: relative;
    /*: 64px;*/
}

.OfferOwnerFooter .TextModule {
    padding-top: 10px;
}

.OfferOwnerFooter .TextModule #consultation {
    padding-top: 20px;
}

.OfferOwnerFooter .TextModule .TextModuleBox h3 {
    padding-top: 25px;
    font-size: 17px;
    text-align: left;
}
