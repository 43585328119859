

@media only screen and (max-width: 1024px) {
    .ColumnImage {
        display: none;
    }
    .IconLeft, .IconRight {
        display: block;
        float: left !important;
        margin-right: 10px;
        margin-left: 10px;
    }
    .ColumnRight, .ColumnLeft {
        float: none !important;
        width: 100% !important;
        text-align: left !important;
    }
    .GridRow {
        margin-bottom: 20px;
    }
    .OfferContent {
        padding-bottom: 0px;
    }
    .ColumnRight {
        padding-top: 0px !important;
    }
}


.WrapperOffer {
    line-height: 29px;
    text-transform: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
}


.WrapperOffer .TextModule h2 {
    color: #212529;
    font-size: 30px;
    line-height: 50px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.WrapperOffer .Separator {
    margin-top:35px;
    margin-bottom:0px
}

.WrapperOffer .TextModule p {
    padding-top: 15px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0px;
    display: block;
    text-align: justify;

}

.GridContainer {
    margin-left: auto;
    margin-right: auto;
    /*max-width: 1200px;*/
    padding-left: 0px;
    padding-right: 0px;
}

.GridContainer .GridRow {
    padding-bottom: 10px;
}

.GridContainer h3 {
    font-size: 17px;
    text-transform: uppercase;
}

.GridContainer p {
    color: #999999;;
    line-height: 1.6;
    padding-top: 0px !important;
}

.GridContainer .ColumnLeft {
    float: left;
    width: 33.33333%;
    text-align: right;
    padding-top: 80px;
}

.GridContainer .GridIcon .IconLeft {
    color: #7f8c8d !important;
    border: 1px solid #7f8c8d !important;
    float: left;
    margin-right: 10px;
    margin-bottom: 20px;
    width: 50px;
    height: 50px;
    padding: 10px;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    background: transparent;
}

.GridContainer .GridIcon .IconRight {
    color: #7f8c8d !important;
    border: 1px solid #7f8c8d !important;
    float: right;
    margin-left: 10px;
    margin-bottom: 20px;
    width: 50px;
    height: 50px;
    padding: 10px;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    background: transparent;
}


.GridContainer .ColumnImage {
    float: left;
    width: 33.33333%;
}
.GridContainer .GridContainer .Image {
    max-width: 250px;
}

.GridContainer .GridContainer .Image img {
    max-width: 100%;
    margin: 0 auto;
    /*vertical-align: bottom;*/
}

.GridContainer .ColumnRight {
    float: right;
    width: 33.33333%;
    text-align: left;
    padding-top: 80px;
}
