.WrapperHomestaging {
    line-height: 29px;
    text-transform: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
}

.WrapperHomestaging .TextModule h2 {
    color: #212529;
    font-size: 30px;
    line-height: 50px;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
}

.WrapperHomestaging .TextModule p {
    padding-top: 15px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0px;
    display: block;
    text-align: justify;

}

.WrapperHomestaging .TextModule ul li {
    padding-top: 15px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: justify;

}

.WrapperHomestaging .TextModule .TextModuleBox h3 {
    padding-top: 25px;
    font-size: 17px;
    text-transform: uppercase;
    text-align: left;
    text-decoration: underline;
}


.WrapperHomestaging .TextModule .banerKreatorki {
    text-align: center;
}
.WrapperHomestaging .TextModule .EbookLeft  {
    position: relative;
    float: left;
    width: 50%;
}
.WrapperHomestaging .TextModule .EbookRight  {
    float: left;
    text-align: right;
    width: 50%;
}

.WrapperHomestaging .TextModule .EbookRight button  {
    background-color: #dda5a6 !important;
    border: none !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    color: #00483a !important;
    cursor: pointer;
    font-family: 'Lato', Arial, Helvetica, sans-serif !important;
    font-size: 17px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    height: 40px;
    padding: 10px !important;
    width: 100% !important;
    box-sizing: border-box !important;
}
.WrapperHomestaging .TextModule .EbookRight img {
    width: 90%;
}

.aws-btn {
    --button-primary-color: #dda5a6 !important;
    --button-primary-color-hover: #F7E3DD !important;
}
