.carousel-item {
    height: 100vh;
    min-height: 300px;
    background: no-repeat center center scroll;
    /*background: no-repeat fixed top center;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /*background-attachment: scroll;*/
}

.carousel-indicators li {
    position: relative;
    display: inline-block;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 8px !important;
    height: 8px !important;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.5);
}
