.Logo {
    background: transparent;
    vertical-align: baseline;
    display: block !important;
    margin: 10px;
    position: relative;
    z-index: 100;
    text-align: center;
}

.Logo svg {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);

}

.Logo svg path {
    fill: #2a3a47;
    -webkit-transition: fill .125s ease;
    transition: fill .125s ease;
    cursor: pointer;
}

.cls-1{fill:#fff;}.cls-2{fill:#223057;}.cls-3{fill:#dfb769;}.cls-4{fill:#eacb7f;}.cls-5{fill:#ddae5f;}.cls-6{fill:#ddad61;}.cls-7{fill:#eacd83;}.cls-8{fill:#deb163;}.cls-9{fill:#deaf5f;}.cls-10{fill:#e4c072;}.cls-11{fill:#e4c277;}.cls-12{fill:#c07c4e;}.cls-13{fill:#deb366;}.cls-14{fill:#d79f57;}.cls-15{fill:#e1b96d;}.cls-16{fill:#e0b567;}.cls-17{fill:#cc8851;}.cls-18{fill:#daad62;}.cls-19{fill:#e4bb69;}.cls-20{fill:#e3bb71;}.cls-21{fill:#c88c51;}.cls-22{fill:#e3bd72;}.cls-23{fill:#d7a65a;}.cls-24{fill:#cf9556;}.cls-25{fill:#d4b46f;}.cls-26{fill:#e1bb71;}.cls-27{fill:#c58651;}.cls-28{fill:#243157;}.cls-29{fill:#213057;}.cls-30{fill:#dbae66;}
/*.Logo:hover {*/
/*fill: #7c7c7c;*/
/*cursor: pointer;*/
/*}*/

/*.Logo:hover svg path {*/
/*fill: #7c7c7c    !important;*/
/*}*/
