.BarWrapper {
    font-family: Raleway, sans-serif;
    display: table-cell;
    text-align: center;
    line-height: 22px;
    width: 1%;
    font-size: 10px;
    letter-spacing: 1px;
    font-style: normal;
    color: #7c7c7c;
    padding-top: 10px;
}
