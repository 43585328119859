body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.selector-for-some-widget {
  box-sizing: content-box;
}

.Logo {
    background: transparent;
    vertical-align: baseline;
    display: block !important;
    margin: 10px;
    position: relative;
    z-index: 100;
    text-align: center;
}

.Logo svg {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);

}

.Logo svg path {
    fill: #2a3a47;
    transition: fill .125s ease;
    cursor: pointer;
}

.cls-1{fill:#fff;}.cls-2{fill:#223057;}.cls-3{fill:#dfb769;}.cls-4{fill:#eacb7f;}.cls-5{fill:#ddae5f;}.cls-6{fill:#ddad61;}.cls-7{fill:#eacd83;}.cls-8{fill:#deb163;}.cls-9{fill:#deaf5f;}.cls-10{fill:#e4c072;}.cls-11{fill:#e4c277;}.cls-12{fill:#c07c4e;}.cls-13{fill:#deb366;}.cls-14{fill:#d79f57;}.cls-15{fill:#e1b96d;}.cls-16{fill:#e0b567;}.cls-17{fill:#cc8851;}.cls-18{fill:#daad62;}.cls-19{fill:#e4bb69;}.cls-20{fill:#e3bb71;}.cls-21{fill:#c88c51;}.cls-22{fill:#e3bd72;}.cls-23{fill:#d7a65a;}.cls-24{fill:#cf9556;}.cls-25{fill:#d4b46f;}.cls-26{fill:#e1bb71;}.cls-27{fill:#c58651;}.cls-28{fill:#243157;}.cls-29{fill:#213057;}.cls-30{fill:#dbae66;}
/*.Logo:hover {*/
/*fill: #7c7c7c;*/
/*cursor: pointer;*/
/*}*/

/*.Logo:hover svg path {*/
/*fill: #7c7c7c    !important;*/
/*}*/

html {
    height: 100%;
    margin: 0!important;
    transition: all 1.3s ease-out;
}

body {
    background-color: #ffffff;
    font-family: 'Raleway', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    color: #7c7c7c;
    font-weight: 400;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden !important;
}

.App {
    width: 100%;
    overflow: hidden;
    /*position: relative;*/
    z-index: 1000;
}

.App,
.Content {
    background-color: #ffffff;
    display: block;
}

/*.Content {*/
/*margin-top: 0!important;*/
/*padding-top: 0!important;*/
/*}*/

a {
    text-decoration: none;
    cursor: pointer;
    transition: color .3s ease-in-out;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
}

p,
h2,
h3 {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
}

h4 {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
}



@media  only screen and (max-device-width: 576px) {
    aside.Nav {
        display: none;
    }
    header nav {
        padding: 10px;
        display: flex;
    }
    header nav h2 {
        font-size: 1.6rem;
    }
    .FooterLogo {
        font-size: 12px;
    }
    /*.SliderButton {*/
    /*top: 100px;*/
    /*bottom: 0 !important;*/
    /*}*/
    .SlideCenterText {
        font-size: 22px !important;
        padding: 22px 0px !important;
    }
    .SectionImage {
        width: 50% !important;
    }
    .SectionGallery {
        height: 780.968px !important;
    }
    .TextModule h2 {
        font-size: 25px !important;
    }
    .RoomsGallery {
        padding-bottom: 0px !important;
    }
    .ContentFormText h2 {
        font-size: 16px !important;
    }
    .ContentFormText h4 {
        font-size: 12px !important;
    }
    .FooterMenu ul li {
        -webkit-margin-after: 0em !important;
    }
    .Social .AwesomeIcon:hover {
        color: #fff !important;
    }
}
/*
@media only screen and @media (min-width: 577px) and (max-width: 768px) {
 */

@media (min-device-width: 577px) and (max-width: 768px) {
    aside.Nav {
        display: none;
    }
    header nav {
        padding: 15px;
        display: flex;
    }
    .SlideCenterText {
        font-size: 30px !important;
    }
    .SectionGallery {
        height: 550.968px !important;
    }
    .SectionImage {
        width: 33% !important;
    }
    .Social .AwesomeIcon:hover {
        color: #fff !important;
    }
    /*.SliderButton {*/
    /*top: 100px;*/
    /*bottom: 0  !important;*/
    /*}*/
    /*.SlideCenterText {*/
    /*top: 60px;*/
    /*bottom: 0 !important;*/
    /*}*/
}

@media only screen and (min-width: 768px) {
    aside.Nav {
        display: block;
    }
    header nav {
        display: none;
    }
    .SectionGallery {
        height: 389.876px !important;
    }
    .Slider,
    .SliderContent,
    .SlideCarusel,
    .InstagramSlideCarusel,
    .TextContent,
    .FooterContent,
    .OfferContent,
    .OfferOwnerContent,
    .HomestagingContent,
    .RoomsGallery,
    .ContactFormContent {
        padding-left: 260px;
    }
    .SocialFooter {
        display: none;
    }
}


/*aside.Nav{*/
/*background-color: #ffffff;*/
/*}*/

/*aside.Nav{*/
/*padding: 0px 1% 0 1% ;*/
/*}*/

/*aside .Nav,*/
/*aside .Nav p,*/
/*aside .Nav span{*/
/*color: #1b1b1b;*/
/*}*/

/*nav.vertical_menu > ul > li > a{*/
/*color: #1b1b1b;        */
/*font-family: 'Raleway', sans-serif;*/
/*font-size: 12px;      */
/*line-height: 42px;    */
/*font-style: normal;   */
/*font-weight: 700;     */
/*text-transform: uppercase;    */
/*letter-spacing: 1.5px;*/
/*}*/



.App {
    /*position: relative;*/
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    outline: 0;
    /*text-align: center;*/
}

/*.App-logo {*/
/*animation: App-logo-spin infinite 20s linear;*/
/*height: 80px;*/
/*}*/

/*.App-header {*/
/*background-color: #222;*/
/*height: 150px;*/
/*padding: 20px;*/
/*color: white;*/
/*}*/

/*.App-title {*/
/*font-size: 1.5em;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*from { transform: rotate(0deg); }*/
/*to { transform: rotate(360deg); }*/
/*}*/

.Content {
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    outline: 0;
    z-index: 100;
    text-align: center;
}

.Slider {
    transition: .6s ease-in-out left;
    z-index: 10;
}

.Slide {
    overflow:hidden;
    height: 100%;
    max-width: 100%;
    margin: 0 auto;
    opacity: 1;
    vertical-align: middle;
    background-size: cover;

}


.slick-slide-t {
    /*background-image: url("http://stockholm16.select-themes.com/wp-content/uploads/2017/07/slide-1.jpg");*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-size:100%;
    overflow:hidden;
    height: auto;
    max-width: 100%;

}

/*.slick-slide .slick-slide2 {*/
/*background-image: url("http://stockholm16.select-themes.com/wp-content/uploads/2017/07/slide-2.jpg");*/
/*background-position: center;*/
/*background-repeat: no-repeat;*/
/*background-size: cover;*/
/*background-size:100%;*/
/*overflow:hidden;*/
/*height: 100%;*/
/*max-width: 100%;*/

/*}*/
/*.slick-list,*/
/*.slick-track {*/
/*height: 100%;*/
/*}*/

/*.a-slide{ height: 100%; width: 100%; background-size: cover !important;}*/
/*.slick-slider{ height: 100%;}*/
/*.slide1 {background:url("http://stockholm16.select-themes.com/wp-content/uploads/2017/07/slide-1.jpg") no-repeat center;}*/
/*.slide2 {background:url("http://stockholm16.select-themes.com/wp-content/uploads/2017/07/slide-2.jpg") no-repeat center;}*/

.SlideCenter {
    /*background-image: url("http://stockholm16.select-themes.com/wp-content/uploads/2017/07/slide-1.jpg");*/
}

.SlideCenterText {
    bottom: 50px;
    color: #1d1d1d;
    font-size: 40px;
    font-family: 'Domine';
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    right: 0;
    padding: 22px 62px;
    min-height: 38px;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 150;
    position: relative;
    display: inline-block;
}

.SliderButtonHolder {
}

.SliderButton {
    bottom: 20px;
    font-family: 'Raleway';
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 2.3px;
    line-height: 52px;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    position: relative;
    display: inline-block;
    color: #ffffff;
    background-color: transparent;
    border-color: #ffffff;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-width: 1px;
    border-style: solid;
    z-index: 150;
    -webkit-transform: translateZ(0px);
    padding: 0 22px;
}

.SliderButton:hover {
    color: #000000;
    background-color: #ffffff;
    text-decoration: none;
    display: inline-block;
}

.FixedContent {
    position: fixed;
    width: 260px;
    height: 100%;
    background-position: right top;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    z-index: 0;
    transition: opacity .3s ease;
    opacity: 1;
}

aside {
    overflow: hidden;
    outline: none;
    position: fixed;
    width: 260px;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 101;
    -webkit-backface-visibility: hidden;
    box-sizing: border-box;
    -webkit-transition: background-color .3s ease;
    -moz-transition: background-color .3s ease;
    -o-transition: background-color .3s ease;
    -ms-transition: background-color .3s ease;
    -ms-overflow-style: none;
    transition: background-color .3s ease;
    outline: 0;
    text-align: center;
    overflow-y: scroll !important;
}

aside::-webkit-scrollbar {
    display: none;
}

/*.Navigation {*/
/*text-align: center;*/
/*position: relative;*/
/*!*width: 260px;*!*/
/*!*height: 100%;*!*/
/*!*background: transparent;*!*/
/*!*background-color: #ffffff;*!*/
/*!*background-position: right top;*!*/
/*!*background-repeat: no-repeat;*!*/
/*!*background-size: cover;*!*/
/*!*box-sizing: border-box;*!*/
/*!*top: 0;*!*/
/*!*left: 0;*!*/
/*z-index: 101;*/
/*transition: background-color .3s ease;*/
/*opacity: 1;*/
/*!*overflow-y: scroll;*!*/
/*!*overflow-x: hidden !important;*!*/
/*outline: none;*/
/*border: 0;*/
/*margin: 0;*/
/*padding: 0;*/
/*vertical-align: baseline;*/
/*outline: 0;*/
/*display: block;*/
/*font-family: 'Raleway', sans-serif;*/
/*color: #7c7c7c;*/
/*font-size: 15px;*/
/*font-weight: 400;*/

/*}*/

/*.ScrollContent {*/
/*position: relative;*/
/*overflow-y: scroll !important;*/
/*-ms-overflow-style: none;*/
/*overflow: -moz-scrollbars-none;*/
/*}*/

.ScrollContent {
    position: relative;
    overflow-x: hidden !important;
    overflow-y: scroll !important;
    z-index: 101;
    visibility: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.ScrollContent::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.Navigation ul {
    margin: 42px 0 0;
    font-size: 12px;
    line-height: 23px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-decoration: none;
    cursor: pointer;
    color: #7c7c7c;
    display: block;
    background: transparent;
    border: 0;
    padding: 0;
    vertical-align: baseline;
    outline: 0;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 0px;
    text-align: center;
    -webkit-font-smoothing: antialiased;

}

.Navigation ul li {
    list-style: none;
    line-height: 35px;
    letter-spacing: 1px;
    display: list-item;
    text-align: -webkit-match-parent;

}

.Navigation ul li a {
    color: #7c7c7c;
    text-decoration: none;
}

.Navigation ul li a:hover {
    list-style: none;
    /*color: #2a3a47;*/
    color: #1f2f54;
    display: list-item;
    text-align: -webkit-match-parent;

}

.Social {
    position: relative;
    background: transparent;
    border: 0;
    margin: 50px 0 0 0;
    padding: 0;
    padding-bottom: 20px;
    vertical-align: baseline;
    outline: 0;
    text-align: center;
    display: block;
}

.AwesomeIcon {
    color: rgb(141, 141, 141) !important;
    margin: 0 8px 0 0;
    font-size: 20px;
}

.AwesomeIcon:hover {
    /*color: #2a3a47 !important;*/
    color: #1f2f54 !important;
    cursor: pointer;
}

.Header {
    position: relative;
    z-index: 110;
    top: 0;
    left: 0;
    display: block;

}

.Header nav {
    /*padding: .5rem 1rem;*/
    visibility: visible;
    flex-wrap: wrap;
    -webkit-box-align: center;
    justify-content: space-between;
    vertical-align: baseline;
}

.Header nav .h2 {
    color: #1d1d1d;
    font-size: 80px;
    font-family: 'Domine', serif;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0px;
    text-transform: capitalize;
    background-color: rgba(255,255,255,0.9);
}

.Header nav button {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    vertical-align: middle;
    display: table-cell;
}

.Header nav ul {
    width: 100%;
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
}
.Header nav div ul li {
    border-bottom: 1px solid #eaeaea;
    margin: 0;
    padding: 0;
    position: relative;
}

.Header nav div ul li a {
    font-size: 13px;
    color: #8d8d8d;
    padding: 9px 0;
    position: relative;
    margin: 0;
    text-transform: none;
    line-height: 17px;
    letter-spacing: 0;
    display: list-item;
    text-align: -webkit-match-parent;
}

/*#back_to_top, #back_to_top.on, #back_to_top.off {*/
    /*right: 50px;*/
/*}*/

#back_to_top.on {
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
    right: 0px;
}

/*#back_to_top.off {*/
    /*opacity: 0;*/
    /*filter: alpha(opacity=0);*/
    /*right: 25px;*/
/*}*/

/*#back_to_top .hover{display:none}*/


#back_to_top>span {
    /*width:46px;*/
    /*height:46px;*/
    line-height:1px;
    /*text-decoration:none;*/
    text-align:center;
    /*font-size:50px;*/
    color:#fff !important;
    padding-left: 10px !important;
    padding-bottom: 12px !important;
    /*background-color:#d1d1d1;*/
    /*border:1px solid transparent;*/
    /*-webkit-transition:background-color .3s ease-in-out,color .3s ease-in-out;*/

    display: inline-block;
    box-sizing: border-box;
    font-family: ETmodules;
    font-size: 96px;
    font-weight: 400;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    -webkit-font-smoothing: antialiased;
    text-transform: none;
    content: attr(data-icon);
    speak: none;

}

#back_to_top:hover > span {
    opacity: 1;
}

#back_to_top {
    /*background: transparent;*/
    height:auto;
    position:fixed;
    /*font-size:20px;*/
    bottom:65px;
    margin:0;
    z-index:10000;
    padding: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    color: #fff;
    background: rgba(0,0,0,.4);
    font-size: 30px;

    /*-webkit-transition: all .3s ease 0s;*/
    /*-moz-transition: all .3s ease 0s;*/
    /*-o-transition: all .3s ease 0s;*/
    /*transition: all .3s ease 0s;*/
    right:25px;
    opacity:0;
    filter:alpha(opacity=0);
    visibility:hidden;
}

a {
    color: #303030;
    text-decoration: none;
    cursor: pointer;
}

.slick-dots {
    bottom: 200px;
    cursor: pointer;
}

.slick-dots ul {
    display: block;
    list-style-type: decimal;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 40px;
}

.slick-dots li button:before {
    font-size: 9px;
    background: transparent;
    color: #fff;
}

.slick-dots li.slick-active button:before {
    color: #fff;
}

.SlideCarusel {
    padding-bottom: 65px;
    /*background-color: transparent;*/
    /*position: relative;*/
}

.PartnershipSlideCarusel {
    padding-bottom: 20px;
    padding-top: 20px;
    /*background-color: transparent;*/
    /*position: relative;*/
}

.PartnershipSlideCaruselItem {
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    opacity: 1;
    vertical-align: center;
    /*height: 254px;*/
    width: 250px;
}

.SlideCaruselItem {
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    opacity: 1;
    vertical-align: middle;
    /*height: 254px;*/
    width: 340px;
}

.SlideCaruselItem:hover {
    cursor: pointer;
    opacity: 0.6;
}

.InstagramSlideCaruselItem {
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    opacity: 1;
    vertical-align: middle;
    /*height: 254px;*/
    width: 150px;
}

.InstagramSlideCaruselItem:hover {
    cursor: pointer;
    opacity: 0.6;
}

.TextContent {
    padding-bottom: 65px;
}

.TextContentWrapper {
    line-height: 29px;
    text-transform: none;
    padding-left: 15px;
    padding-right: 15px;
}

.TextContent .TextContentWrapper .TextModule h2 {
    color: #212529;
    font-size: 30px;
    line-height: 50px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.TextContent .TextContentWrapper .Separator {
    margin-top:35px;
    margin-bottom:0px
}

.TextContent .TextContentWrapper .TextModule p {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0px;
    display: block;
    text-align: justify;
}

.TextModule {
    padding-bottom: 0px;
}

.FooterContent {
    height: 300px;
    /*background: #2a3a47;*/
    background: #1f2f54;
    display : table;
    clear: both;
}

.FooterWrapper {
    font-family: Raleway, sans-serif;
    display: table-cell;
    text-align: center;
    line-height: 22px;
    width: 1%;
    vertical-align: middle;
}

.FooterLogo {
    font-size: 22px;
    letter-spacing: 1.5px;
    font-style: normal;
    color: #ffffff;
}

.FooterBar {
    list-style: none;
    display: table-cell;
    text-align: center;
    padding-left: 260px;
}

.FooterMenu ul {
    color: #7c7c7c;
    list-style: none;
    text-align: center;
    display: block;
    list-style-type: disc;
    vertical-align: baseline;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 40px;
}

.FooterMenu ul li {
    color: #7c7c7c;
    list-style: none;
    text-align: center;
    display: inline-block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 25px;
    -webkit-padding-start: 0px;
}
.FooterMenu ul li a {
    font-family: Raleway, sans-serif;
    font-size: 12px;
    letter-spacing: 1.5px;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    text-decoration: none;
    color: #a8a8a8;
}

.FooterMenu ul li a:hover {
    color: #fff;
}

.ContactFormContent {
    height: 761px;
    background-image: url(http://kucharenka.pl/wp-content/uploads/2018/02/arrangement-2264812_1920.jpg);
    /*background-image: url(images/copy-space-2518265.jpg);*/
    /*background-position: center ;*/
    /*background: no-repeat;*/
    background-attachment: fixed;
    overflow: hidden;
}

.ContactFormWrapper {
    height: 58px;
    text-align: center;
    content: " ";
    width: 66.66666667%;
    padding-top: 5%;
    margin-left: 16.66666667%;
}

.ContactForm {
    position: static;
    text-align: center;
    padding: 0;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center 0;
    background-attachment: fixed;
    overflow: hidden;
}

.ContentFormText {
    color: #fff;

}

.ContentFormText h2 {
    font-family: 'Domine', sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: capitalize;
    line-height: 1.2em;
    display: block;
    -webkit-margin-before: 0.83em;
    -webkit-margin-after: 0.83em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
}

.ContentFormText h4 {
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    display: block;
    -webkit-margin-before: 1.33em;
    -webkit-margin-after: 1.33em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;

}

.FormItem {
    margin-bottom: 22px;
}

.ButtonBorder {
    font-family: 'Raleway';
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 2.3px;
    line-height: 52px;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    position: relative;
    display: inline-block;
    color: #ffffff;
    background-color: transparent;
    border-color: #ffffff;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-width: 1px;
    border-style: solid;
    z-index: 150;
    -webkit-transform: translateZ(0px);
    padding: 0 22px;
}

.ButtonBorder:hover {
    color: #000000;
    background-color: #ffffff;
    text-decoration: none;
    display: inline-block;
}

.OfferContent {
    padding-bottom: 516px;
}

.OfferOwnerContent {
    padding-bottom: 65px;
}

.HomestagingContent {
    padding-bottom: 65px;
}

.RoomsGallery {
    background-color: #f8f8f8 !important;
    padding-bottom: 110px;
}

.SliderContent {
    padding-bottom: 65px;
}

.SeparatorContactForm {
    padding-bottom: 100px;
}

.SeparatorContact {
    padding-bottom: 60px;
}

.ContactFormWrapper .ContentFormText h2 {
    text-transform: uppercase;
    color: #fff;
}

.logo-navbar-mobile {
    height: 45px;

}

.BarWrapper {
    font-family: Raleway, sans-serif;
    display: table-cell;
    text-align: center;
    line-height: 22px;
    width: 1%;
    font-size: 10px;
    letter-spacing: 1px;
    font-style: normal;
    color: #7c7c7c;
    padding-top: 10px;
}



@media only screen and (max-width: 1024px) {
    .ColumnImage {
        display: none;
    }
    .IconLeft, .IconRight {
        display: block;
        float: left !important;
        margin-right: 10px;
        margin-left: 10px;
    }
    .ColumnRight, .ColumnLeft {
        float: none !important;
        width: 100% !important;
        text-align: left !important;
    }
    .GridRow {
        margin-bottom: 20px;
    }
    .OfferContent {
        padding-bottom: 0px;
    }
    .ColumnRight {
        padding-top: 0px !important;
    }
}


.WrapperOffer {
    line-height: 29px;
    text-transform: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
}


.WrapperOffer .TextModule h2 {
    color: #212529;
    font-size: 30px;
    line-height: 50px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.WrapperOffer .Separator {
    margin-top:35px;
    margin-bottom:0px
}

.WrapperOffer .TextModule p {
    padding-top: 15px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0px;
    display: block;
    text-align: justify;

}

.GridContainer {
    margin-left: auto;
    margin-right: auto;
    /*max-width: 1200px;*/
    padding-left: 0px;
    padding-right: 0px;
}

.GridContainer .GridRow {
    padding-bottom: 10px;
}

.GridContainer h3 {
    font-size: 17px;
    text-transform: uppercase;
}

.GridContainer p {
    color: #999999;;
    line-height: 1.6;
    padding-top: 0px !important;
}

.GridContainer .ColumnLeft {
    float: left;
    width: 33.33333%;
    text-align: right;
    padding-top: 80px;
}

.GridContainer .GridIcon .IconLeft {
    color: #7f8c8d !important;
    border: 1px solid #7f8c8d !important;
    float: left;
    margin-right: 10px;
    margin-bottom: 20px;
    width: 50px;
    height: 50px;
    padding: 10px;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    background: transparent;
}

.GridContainer .GridIcon .IconRight {
    color: #7f8c8d !important;
    border: 1px solid #7f8c8d !important;
    float: right;
    margin-left: 10px;
    margin-bottom: 20px;
    width: 50px;
    height: 50px;
    padding: 10px;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    background: transparent;
}


.GridContainer .ColumnImage {
    float: left;
    width: 33.33333%;
}
.GridContainer .GridContainer .Image {
    max-width: 250px;
}

.GridContainer .GridContainer .Image img {
    max-width: 100%;
    margin: 0 auto;
    /*vertical-align: bottom;*/
}

.GridContainer .ColumnRight {
    float: right;
    width: 33.33333%;
    text-align: left;
    padding-top: 80px;
}


.SectionTitleWrapper {
    line-height: 29px;
    text-transform: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 79px;
    padding-bottom: 45px;
}

.SectionTitleWrapper .SectionTitleWrapperText h2 {
    color: #212529;
    font-size: 30px;
    line-height: 50px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.SectionTitleWrapper .Separator {
    margin-top:35px;
    margin-bottom:0px
}

.SectionTitleWrapper .SectionTitleWrapperText h3 {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0px;
}

.SectionTitleWrapper .SectionTitleWrapperText p {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0px;
    display: block;
    text-align: justify;
}
.carousel-item {
    height: 100vh;
    min-height: 300px;
    background: no-repeat center center scroll;
    /*background: no-repeat fixed top center;*/
    background-size: cover;
    /*background-attachment: scroll;*/
}

.carousel-indicators li {
    position: relative;
    display: inline-block;
    flex: 0 1 auto;
    width: 8px !important;
    height: 8px !important;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.5);
}

/* max-width: 990 */
@media only screen and (max-width: 1265px) {
    .OfferOwnerColumnLeft, .OfferOwnerColumnImageRight {
        float: none !important;
        width: 100% !important;
    }
    .OfferOwnerColumnLeft {
        text-align: left !important;
    }
    .OfferOwnerColumnImageRight {
        text-align: center !important;
    }
    .GridRow {
        margin-bottom: 20px;
    }
    .WrapperOfferOwner .OfferOwnerColumnLeft .GridIcon .Icon {
        display: block !important;
        float: left !important;
        margin-right: 10px !important;
        margin-left: 10px !important;
    }
}

.WrapperOfferOwner {
    line-height: 29px;
    text-transform: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
}

.WrapperOfferOwner .TextModule h2 {
    color: #212529;
    font-size: 30px;
    line-height: 50px;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
}

.WrapperOfferOwner .TextModule p {
    padding-top: 15px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0px;
    display: block;
    text-align: justify;

}

.WrapperOfferOwner .OfferOwnerColumnLeft {
    position: relative;
    float: left;
    width: 50%;
    text-align: right;
    padding-top: 80px;


}

.WrapperOfferOwner .OfferOwnerColumnLeft h3 {
    font-size: 17px;
    text-align: left;
    padding-left: 70px;
    text-transform: uppercase;
}

.WrapperOfferOwner .OfferOwnerColumnLeft p  {
    color: #999999;;
    line-height: 1.6;
    padding-top: 0px !important;
    padding-left: 70px
}

.WrapperOfferOwner .OfferOwnerColumnLeft .GridIcon {
    float: left;
    display: inline-block;
    position: relative;
    z-index: 2;
}

.WrapperOfferOwner .OfferOwnerColumnLeft .GridIcon .Icon {
    position: relative;
    color: #7f8c8d !important;
    border: 1px solid #7f8c8d !important;
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    width: 50px;
    height: 50px;
    padding: 10px;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    background: transparent;
}

.WrapperOfferOwner .OfferOwnerColumnImageRight {
    float: left;
    width: 50%;
    text-align: right;
    padding-top: 80px;
}

.WrapperOfferOwner .OfferOwnerColumnImageRight .Image {
    margin-left: 30px;
}

.WrapperOfferOwner .OfferOwnerColumnImageRight .Image img {
    max-width: 100%;
    margin: 0 auto;
    padding-bottom: 85px;
    /*vertical-align: bottom;*/
}

.OfferOwnerMainContent {
    position: relative;
    /*: 64px;*/
}

.OfferOwnerFooter .TextModule {
    padding-top: 10px;
}

.OfferOwnerFooter .TextModule #consultation {
    padding-top: 20px;
}

.OfferOwnerFooter .TextModule .TextModuleBox h3 {
    padding-top: 25px;
    font-size: 17px;
    text-align: left;
}

.WrapperHomestaging {
    line-height: 29px;
    text-transform: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
}

.WrapperHomestaging .TextModule h2 {
    color: #212529;
    font-size: 30px;
    line-height: 50px;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
}

.WrapperHomestaging .TextModule p {
    padding-top: 15px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0px;
    display: block;
    text-align: justify;

}

.WrapperHomestaging .TextModule ul li {
    padding-top: 15px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: justify;

}

.WrapperHomestaging .TextModule .TextModuleBox h3 {
    padding-top: 25px;
    font-size: 17px;
    text-transform: uppercase;
    text-align: left;
    text-decoration: underline;
}


.WrapperHomestaging .TextModule .banerKreatorki {
    text-align: center;
}
.WrapperHomestaging .TextModule .EbookLeft  {
    position: relative;
    float: left;
    width: 50%;
}
.WrapperHomestaging .TextModule .EbookRight  {
    float: left;
    text-align: right;
    width: 50%;
}

.WrapperHomestaging .TextModule .EbookRight button  {
    background-color: #dda5a6 !important;
    border: none !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    color: #00483a !important;
    cursor: pointer;
    font-family: 'Lato', Arial, Helvetica, sans-serif !important;
    font-size: 17px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    height: 40px;
    padding: 10px !important;
    width: 100% !important;
    box-sizing: border-box !important;
}
.WrapperHomestaging .TextModule .EbookRight img {
    width: 90%;
}

.aws-btn {
    --button-primary-color: #dda5a6 !important;
    --button-primary-color-hover: #F7E3DD !important;
}

