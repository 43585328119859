
.SectionTitleWrapper {
    line-height: 29px;
    text-transform: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 79px;
    padding-bottom: 45px;
}

.SectionTitleWrapper .SectionTitleWrapperText h2 {
    color: #212529;
    font-size: 30px;
    line-height: 50px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.SectionTitleWrapper .Separator {
    margin-top:35px;
    margin-bottom:0px
}

.SectionTitleWrapper .SectionTitleWrapperText h3 {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0px;
}

.SectionTitleWrapper .SectionTitleWrapperText p {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0px;
    display: block;
    text-align: justify;
}